import { css } from 'styled-components';
export * from './common.css';

const shadows = {
  xs: [
    //
    `0.5px 1px 1px hsla(var(--hsl-sh), 0.7)`,
  ],
  s: [
    //
    `0.5px 1px 1px hsla(var(--hsl-sh), 0.4)`,
    `1px 2px 2px hsla(var(--hsl-sh), 0.4)`,
  ],
  m: [
    //
    `1px 2px 2px hsla(var(--hsl-sh), 0.27)`,
    `2px 4px 4px hsla(var(--hsl-sh), 0.27)`,
    `4px 8px 8px hsla(var(--hsl-sh), 0.27)`,
  ],
  l: [
    //
    `1px 2px 2px hsla(var(--hsl-sh), 0.2)`,
    `2px 4px 4px hsla(var(--hsl-sh), 0.2)`,
    `4px 8px 8px hsla(var(--hsl-sh), 0.2)`,
    `8px 16px 16px hsla(var(--hsl-sh), 0.2)`,
  ],
  xl: [
    //
    `1px 2px 2px hsla(var(--hsl-sh), 0.17)`,
    `2px 4px 4px hsla(var(--hsl-sh), 0.17)`,
    `4px 8px 8px hsla(var(--hsl-sh), 0.17)`,
    `8px 16px 16px hsla(var(--hsl-sh), 0.17)`,
    `16px 32px 32px hsla(var(--hsl-sh), 0.17)`,
  ],
};

export const section = css`
  padding-top: calc(var(--sy) / 2);
  margin-bottom: var(--sy);
  --sy: 20px;
  @media (min-width: 24em) {
    --sy: 24px;
  }
  @media (min-width: 36em) {
    --sy: 30px;
  }
  @media (min-width: 48em) {
    --sy: 36px;
  }
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: var(--brand-color);
  }
`;

export const newsBar = css`
  --cols: 1;
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(var(--cols), minmax(0, 640px));
  justify-content: center;
  --hsl-sh: 0deg, 0%, 50%;
  filter: ${shadows.s.map((s) => `drop-shadow(${s})`).join(' ')};
  @media (min-width: 60em) {
    --cols: 3;
    justify-content: start;
    text-align: left;
  }
`;

export const newsLink = css`
  --bg: white;
  --eyebrow: #4a457c;
  --heading: #343434;
  --content: #707a88;
  --action: #5a17ee;
  --border: #5a17ee;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  --rad: 16px;
  display: block;
  background: var(--bg);
  text-decoration: none;
  transition: background 100ms linear, color 100ms linear;
  overflow: hidden;
  border-color: var(--border);
  border-style: solid;
  border-width: 2px 0;

  &:first-child {
    border-top-left-radius: var(--rad);
    border-top-right-radius: var(--rad);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-width: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--rad);
    border-bottom-right-radius: var(--rad);
    border-bottom-width: 0;
  }

  @media (min-width: 60em) {
    border-width: 0 2px;

    &:first-child {
      border-top-left-radius: var(--rad);
      border-top-right-radius: 0;
      border-bottom-left-radius: var(--rad);
      border-bottom-right-radius: 0;
      border-left-width: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: var(--rad);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: var(--rad);
      border-right-width: 0;
    }
  }
`;

export const newsItem = css`
  padding: 20px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  @media (min-width: 60em) {
    border: none;
  }
  > * + * {
    margin-top: 10px !important;
  }
  > *:last-child {
    padding-top: 20px;
    margin-top: auto !important;
  }
`;

export const eyebrow = css`
  color: var(--eyebrow) !important;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;

export const heading = css`
  color: var(--heading);
  margin: 0;
  font-size: var(--fz);
  --fz: 16px;
  @media (min-width: 24em) {
    --fz: 18px;
  }
  @media (min-width: 36em) {
    --fz: 20px;
  }
`;

export const content = css`
  margin: 0;
  color: var(--content);
  font-size: var(--fz);
  line-height: calc(var(--fz) + 8px);
  --fz: 14px;
  @media (min-width: 21em) {
    /* --fz: 16px; */
  }
  @media (min-width: 36em) {
    --fz: 16px;
  }
`;

export const cta = css`
  color: var(--action) !important;
  font-weight: 500;
  font-size: var(--fz);
  line-height: calc(var(--fz) + 8px);
  --fz: 14px;
  @media (min-width: 21em) {
    /* --fz: 16px; */
  }
  @media (min-width: 36em) {
    --fz: 16px;
  }
`;
