/* eslint-disable react/display-name */
import React from 'react';

import { Link } from 'src/containers/Link';
import * as css from './drawer-news-bar.css';
import * as sys from '../../system';

/*

**TEI Forrester Report**

[https://info.rasa.com/forrester-tei-report-2021](https://info.rasa.com/forrester-tei-report-2021)

**Certification Workshop**

[https://rasa.com/blog/rasa-education-and-certification/](https://rasa.com/blog/rasa-education-and-certification/)

**PoC Whitepaper**

[https://info.rasa.com/poc-to-production-whitepaper](https://info.rasa.com/poc-to-production-whitepaper)

 */

export default function NewsBar({ customData }) {
  const parsedCustomData = JSON.parse(customData);

  return (
    <nav css={[css.section]} className="reset2">
      <div css={[sys.wrapLast, sys.frame]}>
        <div css={[css.newsBar]}>
          {parsedCustomData.newsItems && parsedCustomData.newsItems.length > 0 && (
            parsedCustomData.newsItems.map((item) => (
              <Link to={item.cta.href} key={item.heading} css={css.newsLink} target="_blank">
                <article css={[css.newsItem]}>
                  <p className="f-mono" css={[css.eyebrow, css.metaHeading]}>
                    {item.eyebrow}
                  </p>
                  <h3 css={css.heading}>{item.heading}</h3>
                  <p css={[css.cta]}>{item.cta.text}</p>
                </article>
              </Link>
            ))
          )}
        </div>
      </div>
    </nav>
  );
}
